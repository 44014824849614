<template>
  <v-container class="sessionContainer">
    <v-row class="ma-0 pa-0">
      <!-- LOGIN BANNER - UTSC Index logo and slogan -->
      <v-col v-if="store.app.onDesktop" cols="3" class="sessionSidePanel backgroundGradient pl-12 pr-9">
        <v-row @click="$router.push('/')" class="ma-0">
          <v-icon color="#FFFFFF" class="mr-3 pb-1 pointer" x-large>mdi-book-open-page-variant</v-icon>
          <h1 class="pointer">UofT Index</h1>
        </v-row>
        <v-row class="ml-0 mr-3 subHeadRow">
          <p class="white--text text-lg-h6 text-md-body-1 font-weight-medium pt-4">Helping students make informed decisions one course at a time.</p>
        </v-row>
      </v-col>
      <!-- SESSION AREA - Variable screen that will change depending on the user action -->
      <SessionModal/>
    </v-row>
  </v-container>
</template>

<script>
import { useAllStores } from '@/stores/useAllStores'
import SessionModal from '@/components/SessionModal'

export default {
  name: 'Session',
  components: { SessionModal },
  beforeRouteEnter (to, from, next) {
    next(vm => { if (vm.store.user.userInfo) next('/') })
  },
  setup () {
    return {
      store: useAllStores()
    }
  }
}
</script>

<style scoped>
  .sessionContainer {
    min-width: 100%;
    align-self: center;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
  .sessionSidePanel {
    height: 100vh;
    max-width: 450px;
    padding-top: 6vh;
    color: #FFFFFF;
  }
  .backgroundGradient {
    background: linear-gradient(180deg, #002D63 0%, #00387D 100%);
  }
  .subHeadRow {
    max-width: 400px;
  }

</style>

<template>
  <!-- SESSION AREA - Variable screen that will change depending on the user action -->
  <v-col align-self="center" :class="css">
    <v-row v-if="!store.app.onDesktop && $route.path === '/session'" @click="$router.push('/')" class="ma-3 pb-12">
      <v-icon color="accent" class="mr-3 pb-1 pointer" x-large>mdi-book-open-page-variant</v-icon>
      <h1 class="pointer">UofT Index</h1>
    </v-row>
    <v-row justify="center">
      <v-col v-if="!gettingCookie" class="loginCol">
        <!-- LOGIN AREA - Login screen + forgot password option -->
        <v-row v-if="screen === 'LgEmail'" justify="center">
          <v-col class="pa-0 ma-0">
            <v-row justify="center">
              <p class="text-h4 text-sm-h3 mb-2 font-weight-medium text-center">Welcome back!</p>
            </v-row>
            <v-row justify="center" class="my-8">
              <v-btn class="text-capitalize px-6" outlined large color="accent" @click="ssoAuth('google')">
                <v-icon class="">mdi-google</v-icon></v-btn>
              <v-btn class="text-capitalize mx-5  px-6" outlined large color="accent" @click="ssoAuth('microsoft')">
                <v-icon class="">mdi-microsoft</v-icon></v-btn>
              <v-btn class="text-capitalize px-6" outlined large color="accent" @click="ssoAuth('github')">
                <v-icon class="">mdi-github</v-icon></v-btn>
            </v-row>
            <v-row align="center" class="mb-8"><v-divider/><p class="mx-5 my-0 text--secondary">or login via Email</p><v-divider/></v-row>
            <ValidationObserver ref="observer" v-slot="{ validate }">
              <form>
                <ValidationProvider v-slot="{ errors }" name="Email" rules="required|email">
                  <v-text-field color="accent" prepend-inner-icon="mdi-email" data-cy="email_field" placeholder="E-mail" outlined
                                :error-messages="errors" v-model="userCredentials.email" required></v-text-field>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" name="Password" rules="required|min:8">
                  <v-text-field color="accent" prepend-inner-icon="mdi-lock" data-cy="password_field" placeholder="Password" outlined
                                :error-messages="errors" v-model="userCredentials.password" type="Password"
                                required class="pt-2"></v-text-field>
                </ValidationProvider>
                <v-row justify="end" class="pr-5">
                  <p class="text-caption font-weight-medium">{{ passLength }}</p>
                </v-row>
                <v-row class="pr-4 pb-2 mt-1 blue--text justify-end">
                  <h4 @click="switchScreens('ResetPass')" class="accent--text hover">Forgot my password</h4>
                </v-row>
                <v-alert v-if="authError()" type="error" text dense outlined border="left" class="mt-5 mb-0" icon="mdi-alert-outline">
                  {{ store.errors.auth.message }}
                </v-alert>
                <v-btn class="mt-8 mb-2 methodBtn" depressed color="accent" data-cy="login_btn" @click="submit" :loading="btnLoading">LOGIN</v-btn>
              </form>
              <a v-if="validate"></a>
            </ValidationObserver>
          </v-col>
        </v-row>
        <!-- SIGN UP AREA - Sign up screen for new users -->
        <v-row v-else-if="screen === 'SuEmail'" justify="center">
          <v-col class="pa-0 ma-0">
            <v-row justify="center">
              <p class="text-h4 text-md-h3 text-lg-h3 font-weight-medium text-center">Create an account</p>
            </v-row>
            <v-row justify="center" class="my-8">
              <v-btn class="text-capitalize px-6" outlined color="accent" @click="ssoAuth('google')">
                <v-icon class="">mdi-google</v-icon></v-btn>
              <v-btn class="text-capitalize mx-5  px-6" outlined color="accent" @click="ssoAuth('microsoft')">
                <v-icon class="">mdi-microsoft</v-icon></v-btn>
              <v-btn class="text-capitalize px-6" outlined color="accent" @click="ssoAuth('github')">
                <v-icon class="">mdi-github</v-icon></v-btn>
            </v-row>
            <v-row align="center" class="mb-8"><v-divider/><p class="mx-5 my-0 text--secondary">or sign up via Email</p><v-divider/></v-row>
            <ValidationObserver ref="observer" v-slot="{ validate }">
              <form>
                <ValidationProvider v-slot="{ errors }" name="Email" rules="required|email">
                  <v-text-field color="accent" prepend-inner-icon="mdi-email" placeholder="E-mail" outlined
                                :error-messages="errors" v-model="userCredentials.email" required></v-text-field>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" name="Password" rules="required|min:8">
                  <v-text-field color="accent" prepend-inner-icon="mdi-lock" placeholder="Password" outlined
                                :error-messages="errors" v-model="userCredentials.password" type="Password"
                                required class="pt-2"></v-text-field>
                </ValidationProvider>
                <v-row justify="end" class="pr-5">
                  <p class="text-caption font-weight-medium">{{ passLength }}</p>
                </v-row>
                <v-alert v-if="authError()" type="error" text dense outlined border="left" class="mt-5 mb-0" icon="mdi-alert-outline">
                  {{ store.errors.auth.message }}
                </v-alert>
                <v-btn class="mt-8 mb-2 methodBtn" depressed color="accent" @click="submit" :loading="btnLoading">SIGN UP</v-btn>
              </form>
              <a v-if="validate"></a>
            </ValidationObserver>
          </v-col>
        </v-row>
        <!-- PASSWORD RESET - Allow users to request password reset through email link -->
        <v-row v-else justify="center">
          <v-col class="pa-0 ma-0">
            <v-row justify="center">
              <p class="text-h4 text-md-h3 text-lg-h3 font-weight-medium text-center">Reset password</p>
            </v-row>
            <v-row justify="center" class="pb-6">
              <p class="text-body-1 mb-8 text-center">Enter the email associated with your account and an email
                to reset your password will be sent to you shortly!</p>
            </v-row>
            <v-text-field color="accent" prepend-inner-icon="mdi-email" placeholder="E-mail" outlined
                          v-model="userCredentials.email" required></v-text-field>
            <v-alert v-if="authError()" type="error" text dense outlined border="left" class="my-5" icon="mdi-alert-outline">
              {{ store.errors.auth.message }}
            </v-alert>
            <v-btn class="mt-8 methodBtn" depressed color="accent" @click="resetPassword">SEND EMAIL</v-btn>
          </v-col>
        </v-row>
        <!-- SESSION FOOTER - Navigation so users can go between session screens -->
        <v-row v-if="screen === 'SuEmail'" justify="center">
          <v-col class="pa-0 mt-12">
            <v-row justify="center">
              <p>Already a member?</p>
              <p class="accent--text ml-2 font-weight-medium hover" @click="switchScreens('LgEmail')">Login</p>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-else-if="screen === 'LgEmail'" justify="center">
          <v-col class="pa-0 mt-12">
            <v-row justify="center">
              <p>Don't have an account?</p>
              <p class="accent--text ml-2 font-weight-medium hover" @click="switchScreens('SuEmail')">Sign Up</p>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-else justify="center">
          <v-col class="pa-0 ma-0">
            <v-row justify="center">
              <v-divider class="my-10 sectionDivider"/>
            </v-row>
            <v-row justify="center"><p>Whoops I suddenly remember...</p></v-row>
            <v-row justify="center" class="px-3">
              <v-btn class="methodBtn" id="login_button" color="accent" outlined dark @click="switchScreens('LgEmail')">LOGIN</v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else class="loginCol text-center mb-12 pb-12 pt-0">
        <p class="text-h4 text-md-h3 text-lg-h3 font-weight-medium text-center">Welcome back!</p>
        <p class="mb-12 text-center font-weight-medium">Getting a 🍪 from the cookie jar. You will be redirected shortly...</p>
        <v-progress-circular indeterminate size="64" width="6" color="accent" class="py-12 my-12"/>
      </v-col>
    </v-row>
  </v-col>

</template>

<script>
import { useAllStores } from '@/stores/useAllStores'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  name: 'SessionModal',
  props: {
    loading: { type: Boolean, default: false },
    userAuth: { type: Object, default: () => {} },
    css: { type: String, default: '' }
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    screen: 'LgEmail',
    btnLoading: false,
    gettingCookie: false,
    userCredentials: { email: '', password: '' }
  }),
  mounted () {
    document.addEventListener('keydown', this.onDocumentKeydown)
    this.screen = this.store.app.defaultSessionPage
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.onDocumentKeydown)
    this.store.app.defaultSessionPage = 'LgEmail'
  },
  watch: {
    userAuth: function (newValue) {
      if (newValue.user) this.getSession(newValue)
    },
    loading: function (newValue) {
      this.gettingCookie = newValue
    }
  },
  computed: {
    passLength () {
      return this.userCredentials.password.length
    }
  },
  methods: {
    submit () {
      this.$refs.observer.validate().then((value) => {
        if (value) {
          this.btnLoading = true
          if (this.screen === 'LgEmail') {
            this.login()
          } else {
            this.signUp()
          }
        }
      })
    },
    login () {
      firebase.auth().signInWithEmailAndPassword(this.userCredentials.email, this.userCredentials.password)
        .then(data => {
          this.$gtag.event('login_email', { value: 1 })
          this.getSession(data)
        })
        .catch(err => {
          this.store.errors.auth.error = true
          this.store.errors.auth.message = err.message
        })
    },
    signUp () {
      firebase.auth().createUserWithEmailAndPassword(this.userCredentials.email, this.userCredentials.password)
        .then(data => {
          this.$gtag.event('signup_email', { value: 1 })
          this.getSession(data)
        })
        .catch(err => {
          this.store.errors.auth.error = true
          this.store.errors.auth.message = err.message
        })
    },
    ssoAuth (provider) {
      let ssoProvider = null

      if (provider === 'google') {
        ssoProvider = new firebase.auth.GoogleAuthProvider()
      } else if (provider === 'microsoft') {
        ssoProvider = new firebase.auth.OAuthProvider('microsoft.com')
      } else {
        ssoProvider = new firebase.auth.GithubAuthProvider()
      }
      // Call firebase SSO popup
      firebase.auth().signInWithPopup(ssoProvider).then((result) => {
        this.gettingCookie = true
        this.getSession(result)
      }).catch(err => {
        this.store.errors.auth.error = true
        this.store.errors.auth.message = err.message
      })
    },
    resetPassword () {
      firebase.auth().sendPasswordResetEmail(this.userCredentials.email).then(() => {
        this.$gtag.event('password_reset', { value: 1 })
        this.store.errors.resetAuthError()
        this.screen = 'LgEmail'
        this.$toast.info('Email was sent!')
      }).catch(err => {
        this.store.errors.auth.error = true
        this.store.errors.auth.message = err.message
      })
    },
    authError () {
      if (this.store.errors.auth.error) {
        this.btnLoading = false
        this.loading = false
      }
      return this.store.errors.auth.error
    },
    onDocumentKeydown (e) {
      if (e.key === 'Enter') {
        e.preventDefault()
        if (this.screen === 'ResetPass') {
          this.resetPassword()
        } else {
          this.submit()
        }
      }
    },
    async getSession (data) {
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken => {
          const queryObj = {
            query: 'mutation createSession ($uid: String!, $idToken: String!) { ' +
              'createSession(uid: $uid, idToken: $idToken) { id, username, email, picture, displayName, defaultCampus, verified } }',
            variables: { uid: data.user.uid, idToken: idToken },
            operationName: 'createSession'
          }
          fetch('/graphql', {
            method: 'post',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(queryObj)
          }).then((response) => response.json())
            .then((graphQlRes) => {
              if (graphQlRes.data) {
                this.store.user.setUserState(graphQlRes.data.createSession)
                this.store.errors.resetAuthError()
                this.$emit('loggedIn')
                const curUser = firebase.auth().currentUser
                this.$gtag.event('auth_' + curUser.providerData[0].providerId, { value: 1 })
                if (this.$route.path === '/session') {
                  if (curUser.metadata.creationTime === curUser.metadata.lastSignInTime) {
                    this.store.app.setWelcomeModal(true)
                    this.$router.push('/dashboard')
                  } else {
                    this.$router.push('/')
                  }
                }
              } else {
                this.store.errors.auth.error = true
                this.store.errors.auth.message = graphQlRes.errors[0].message
              }
            }).catch((err) => console.error(err))
        }).catch(err => {
          this.store.errors.auth.error = true
          this.store.errors.auth.message = err.message
        })
    },
    switchScreens (newScreen) {
      this.store.errors.resetAuthError()
      this.userCredentials.password = ''
      this.screen = newScreen
      if (this.$refs.observer) this.$refs.observer.reset()
    }
  }
}
</script>

<style scoped>
  .loginCol {
    max-width: 485px;
    padding-right: 50px;
    padding-left: 50px;
  }
  >>>.v-text-field__details {
    margin-bottom: 0 !important;
  }
  >>>.v-input__icon {
    margin-right: 6px;
  }
  .methodBtn{
    color: #FFFFFF;
    margin-bottom: 20px;
    width: 100%;
    margin-top: 10px;
    height: 45px !important;
    border-radius: 5px;
  }
  .sectionDivider {
    max-width: 500px;
  }
  .pointer:hover {
    cursor: pointer;
  }
  .hover:hover {
    cursor: pointer;
    color: var(--v-primary-base) !important;
    text-decoration: underline;
  }

</style>
